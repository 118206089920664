import {
    createContext,
    useContext,
    useState,
    useCallback,
} from 'react'
import { useMutation } from '@tanstack/react-query'

import * as api from '../api/api'
import { UserContext } from './userContext'

export const GraphContext = createContext(undefined)

export const GraphContextProvider = ({
    children,
    map,
    build,
    getNodeFunc = null,
    graphData: {
        ...graph
    },
    graphStorage,
}) => {
    const { user } = useContext(UserContext)
    const { build: {path} } = map
    const { code_root, code_path, code_bucket, storage_root_url, code_storage, search_index_path, db_path, trie } = build
    const [activeNode, setActiveNode] = useState(null)
    const [newestNode, setNewestNode] = useState(null)
    const [showRefs, setShowRefs] = useState(null)
    const [zoomState, setZoomState] = useState({ scale: 1, positionX: 0, positionY: 0 })
    const [nodes, setNodes] = useState(graph?.nodes || {})
    const [relations, setRelations] = useState(graph?.relations || {})
    const [isDragging, setIsDragging] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    const getNode = getNodeFunc || api.getNode

    const isOwner = map.free || (user && (map.owner === user.uid))

    const { scale } = zoomState

    const buildRef = api.unpackBuildRef(path)
    const codeStorageConfig = {
        backend: code_storage,
        bucket: code_bucket,
        root_url: storage_root_url,
        path: code_path,
        db_path: db_path,
        ...buildRef
    }

    const searchIndexConfig = {
        backend: code_storage,
        bucket: code_bucket,
        root_url: storage_root_url,
        path: search_index_path,
        trie: trie,
        ...buildRef
    }

    const saveNodePosition = useCallback((nodeId) => {
        graphStorage.setGraph({
            [`nodes.${nodeId}`]: nodes[nodeId],
        })
    }, [graphStorage, nodes])

    const saveNewNode = useCallback((nodeId, rect) => {
        graphStorage.setGraph({
            [`nodes.${nodeId}`]: rect,
        })
    }, [graphStorage])

    const saveRemoveNode = useCallback((nodeId) => {
        graphStorage.deleteNode(nodeId)
    }, [graphStorage])

    const saveRelation = useCallback((key, relation) => {
        graphStorage.setGraph({
            [`relations.${key}`]: relation,
        })
    }, [graphStorage])

    const removeNode = useCallback((id) => {
        setNodes((oldNodes) => {
            const newNodes = { ...oldNodes }
            delete newNodes[id]
            return newNodes
        })
        saveRemoveNode(id)
    }, [setNodes, saveRemoveNode])

    const setNode = useCallback((id, rect) =>
        setNodes((prevRects) => ({ ...prevRects, [id]: rect })),
        [setNodes],
    )

    const setRelation = useCallback(({ id, caller, type }) => setRelations((oldRelations) => {
        const key = `${id} ${caller} ${type}`

        if (id !== caller && !oldRelations[key]) {
            const relation = { id, caller, type }
            saveRelation(key, relation)
            return ({
                ...oldRelations,
                [key]: relation,
            })
        }

        return oldRelations
    }), [setRelations, saveRelation])

    const addNode = useCallback(({
        href,
        opener,
        relation,
    }) => getNode(codeStorageConfig, href).then(({ id }) => {

        setActiveNode(id)

        if (!nodes[id]) {
            let rect = {}
            if (opener && nodes[opener]) {
                const openerRect = nodes[opener]
                rect = {
                    top: openerRect?.top,
                    left: openerRect?.left + openerRect?.width + 100,
                }
            } else if (activeNode && nodes[activeNode]) {
                const activeRect = nodes[activeNode]
                rect = {
                    top: activeRect?.top,
                    left: activeRect?.leftactiveRect?.width + 100,
                }
            }
            setNode(id, rect)
            saveNewNode(id, rect)
            setNewestNode(id)
        }

        if (relation === 'container') {
            setRelation({ id: opener, caller: id, type: relation })
        } else if (relation === 'reference') {
            setRelation({ id: id, caller: opener, type: 'reference' })
        } else if (relation === 'parent') {
            setRelation({ id, caller: opener, type: 'container' })
        }

    }), [nodes, setNode, setActiveNode, setNewestNode, setRelation, activeNode, getNode, saveNewNode])

    const addNodeQuery = useMutation({
        mutationFn: addNode,
        mutationKey: 'addNode',
    })

    return (
        <GraphContext.Provider value={{
            rootId: code_root,
            codeStorageConfig,
            searchIndexConfig,
            bucketName: code_bucket,
            searchIndexPath: search_index_path,
            path: code_path,
            nodes,
            relations,
            addNode: isOwner ? addNode : () => {},
            removeNode: isOwner ? removeNode : () => {},
            setNode,
            isDragging,
            setIsDragging,
            showRefs,
            setShowRefs: isOwner ? setShowRefs : () => {},
            newestNode,
            scale,
            zoomState,
            setZoomState,
            activeNode,
            setActiveNode,
            isDisabled,
            setIsDisabled,
            saveNodePosition,
            addNodeQuery,
            isOwner,
            map,
            build,
            getNode,
        }}>
            {children}
        </GraphContext.Provider>
    )
}
