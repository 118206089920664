import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import 'firebaseui/dist/firebaseui.css'

import { ReactComponent as Logo } from '../../Logo.svg'

import styles from './Login.module.css'
import { renderLogin } from './renderLogin'

export const Login = () => {

    const { state } = useLocation()
    const [searchParams] = useSearchParams()

    const redirectUrl = searchParams.get('redirect') || state?.redirect || '/'

    useEffect(() => renderLogin({ redirectUrl }), [])

    return (
        <div className={styles.page}>
            <div className={styles.title}>
                <Logo />
            </div>
            <div id="render-login-ui"></div>
        </div>
    )
}
